body {
  margin: 0;
  padding: 0;
}
div.anime {
  position: relative;
  overflow: hidden;
}

div.absolute {
  z-index: 1;
}

div.anime::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 100%;
  background-color: white;
  top: 0;
  right: 0;
  z-index: 3;
  /* -webkit-animation: 3s rightBlink ease infinite;
  -moz-animation: 3s rightBlink ease infinite;
  -ms-animation: 3s rightBlink ease infinite;
  -o-animation: 3s rightBlink ease infinite;
  animation: 3s rightBlink ease infinite; */

  /* animation: blink 1s step-start 0s infinite; */
}

div.anime::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 100%;
  background-color: white;
  top: 0;
  left: 0;
  z-index: 3;
  /* -webkit-animation: 3s leftBlink ease infinite;
  -moz-animation: 3s leftBlink ease infinite;
  -ms-animation: 3s leftBlink ease infinite;
  -o-animation: 3s leftBlink ease infinite;
  animation: 3s leftBlink ease infinite; */
}

@keyframes leftBlink {
  from,
  to {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@-moz-keyframes leftBlink {
  from,
  to {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@-webkit-keyframes leftBlink {
  from,
  to {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@-ms-keyframes leftBlink {
  from,
  to {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@-o-keyframes leftBlink {
  from,
  to {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

@keyframes rightBlink {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes rightBlink {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes rightBlink {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-ms-keyframes rightBlink {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-o-keyframes rightBlink {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}
